'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Checkbox, Dialog, DialogBody, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, Label } from '..';
import { CircleAlert, Info } from "lucide-react";
import React, { useId } from "react";
export function ActionDialog(props) {
    const okButton = props.okButton === true ? {} : props.okButton;
    const cancelButton = props.cancelButton === true ? {} : props.cancelButton;
    const anyButton = !!(okButton || cancelButton);
    const title = props.title === true ? (props.cancelButton ? "Confirmation" : "Alert") : props.title;
    const TitleIcon = props.titleIcon || (props.danger ? CircleAlert : Info);
    const [openState, setOpenState] = React.useState(!!props.open);
    const open = props.open ?? openState;
    const [confirmed, setConfirmed] = React.useState(false);
    const confirmId = useId();
    const [invalidationCount, setInvalidationCount] = React.useState(0);
    const onOpenChange = (open) => {
        if (!open) {
            props.onClose?.();
            setConfirmed(false);
        }
        else {
            setInvalidationCount(invalidationCount + 1);
        }
        setOpenState(open);
        props.onOpenChange?.(open);
    };
    return (_jsxs(Dialog, { open: open, onOpenChange: onOpenChange, children: [props.trigger && _jsx(DialogTrigger, { asChild: true, children: props.trigger }), _jsxs(DialogContent, { children: [_jsxs(DialogHeader, { children: [_jsxs(DialogTitle, { className: "flex items-center", children: [_jsx(TitleIcon, { className: "h-4 w-4 mr-2" }), title] }), _jsx(DialogDescription, { children: props.description })] }), _jsxs(DialogBody, { className: "pb-2", children: [_jsx("div", { children: props.children }), props.confirmText && _jsx(Alert, { children: _jsxs(Label, { className: "flex gap-4 items-center", children: [_jsx(Checkbox, { id: confirmId, checked: confirmed, onCheckedChange: (v) => setConfirmed(!!v) }), props.confirmText] }) })] }), anyButton && _jsxs(DialogFooter, { className: "gap-2", children: [cancelButton && (_jsx(Button, { variant: "secondary", color: "neutral", onClick: async () => {
                                    if (await cancelButton.onClick?.() !== "prevent-close") {
                                        onOpenChange(false);
                                    }
                                }, ...cancelButton.props, children: "Cancel" })), okButton && (_jsx(Button, { disabled: !!props.confirmText && !confirmed, variant: props.danger ? "destructive" : "default", onClick: async () => {
                                    if (await okButton.onClick?.() !== "prevent-close") {
                                        onOpenChange(false);
                                    }
                                }, ...okButton.props, children: okButton.label ?? "OK" }))] })] })] }, invalidationCount));
}
