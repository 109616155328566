var _a, _b;
import { Result } from "./results";
export class MaybeWeakMap {
    constructor(entries) {
        this[_a] = "MaybeWeakMap";
        const entriesArray = [...entries ?? []];
        this._primitiveMap = new Map(entriesArray.filter((e) => !this._isAllowedInWeakMap(e[0])));
        this._weakMap = new WeakMap(entriesArray.filter((e) => this._isAllowedInWeakMap(e[0])));
    }
    _isAllowedInWeakMap(key) {
        return (typeof key === "object" && key !== null) || (typeof key === "symbol" && Symbol.keyFor(key) === undefined);
    }
    get(key) {
        if (this._isAllowedInWeakMap(key)) {
            return this._weakMap.get(key);
        }
        else {
            return this._primitiveMap.get(key);
        }
    }
    set(key, value) {
        if (this._isAllowedInWeakMap(key)) {
            this._weakMap.set(key, value);
        }
        else {
            this._primitiveMap.set(key, value);
        }
        return this;
    }
    delete(key) {
        if (this._isAllowedInWeakMap(key)) {
            return this._weakMap.delete(key);
        }
        else {
            return this._primitiveMap.delete(key);
        }
    }
    has(key) {
        if (this._isAllowedInWeakMap(key)) {
            return this._weakMap.has(key);
        }
        else {
            return this._primitiveMap.has(key);
        }
    }
}
_a = Symbol.toStringTag;
export class DependenciesMap {
    constructor() {
        this._inner = { map: new MaybeWeakMap(), hasValue: false, value: undefined };
        this[_b] = "DependenciesMap";
    }
    _valueToResult(inner) {
        if (inner.hasValue) {
            return Result.ok(inner.value);
        }
        else {
            return Result.error(undefined);
        }
    }
    _unwrapFromInner(dependencies, inner) {
        if ((dependencies.length === 0)) {
            return this._valueToResult(inner);
        }
        else {
            const [key, ...rest] = dependencies;
            const newInner = inner.map.get(key);
            if (!newInner) {
                return Result.error(undefined);
            }
            return this._unwrapFromInner(rest, newInner);
        }
    }
    _setInInner(dependencies, value, inner) {
        if (dependencies.length === 0) {
            const res = this._valueToResult(inner);
            if (value.status === "ok") {
                inner.hasValue = true;
                inner.value = value.data;
            }
            else {
                inner.hasValue = false;
                inner.value = undefined;
            }
            return res;
        }
        else {
            const [key, ...rest] = dependencies;
            let newInner = inner.map.get(key);
            if (!newInner) {
                inner.map.set(key, newInner = { map: new MaybeWeakMap(), hasValue: false, value: undefined });
            }
            return this._setInInner(rest, value, newInner);
        }
    }
    get(dependencies) {
        return Result.or(this._unwrapFromInner(dependencies, this._inner), undefined);
    }
    set(dependencies, value) {
        this._setInInner(dependencies, Result.ok(value), this._inner);
        return this;
    }
    delete(dependencies) {
        return this._setInInner(dependencies, Result.error(undefined), this._inner).status === "ok";
    }
    has(dependencies) {
        return this._unwrapFromInner(dependencies, this._inner).status === "ok";
    }
    clear() {
        this._inner = { map: new MaybeWeakMap(), hasValue: false, value: undefined };
    }
}
_b = Symbol.toStringTag;
