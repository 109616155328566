"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from "react";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { Button } from "./button";
import { Input } from "./input";
import { cn } from "../../lib/utils";
const PasswordInput = forwardRef(({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    return (_jsxs("div", { className: "relative", children: [_jsx(Input, { type: showPassword ? "text" : "password", className: cn("hide-password-toggle pr-10", className), ref: ref, ...props }), _jsx(Button, { type: "button", variant: "ghost", size: "sm", className: "absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent", onClick: () => setShowPassword((prev) => !prev), disabled: props.disabled, "aria-label": showPassword ? "Hide password" : "Show password", tabIndex: -1, children: showPassword ? (_jsx(EyeIcon, { className: "h-4 w-4", "aria-hidden": "true" })) : (_jsx(EyeOffIcon, { className: "h-4 w-4", "aria-hidden": "true" })) })] }));
});
PasswordInput.displayName = "PasswordInput";
export { PasswordInput };
