"use client";
import { jsx as _jsx } from "react/jsx-runtime";
import { Copy } from "lucide-react";
import React from "react";
import { Button, cn, useToast } from "..";
const CopyButton = React.forwardRef((props, ref) => {
    const { toast } = useToast();
    return (_jsx(Button, { variant: "secondary", ...props, className: cn("h-6 w-6 p-1", props.className), ref: ref, onClick: async (...args) => {
            await props.onClick?.(...args);
            try {
                await navigator.clipboard.writeText(props.content);
                toast({ description: 'Copied to clipboard!', variant: 'success' });
            }
            catch (e) {
                toast({ description: 'Failed to copy to clipboard', variant: 'destructive' });
            }
        }, children: _jsx(Copy, {}) }));
});
CopyButton.displayName = "CopyButton";
export { CopyButton };
