"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Button } from "@stackframe/stack-ui";
import { DataTableViewOptions } from "./view-options";
import { DownloadIcon } from "lucide-react";
import { mkConfig, generateCsv, download } from 'export-to-csv';
export function DataTableToolbar({ table, toolbarRender }) {
    const isFiltered = table.getState().columnFilters.length > 0;
    const isSorted = table.getState().sorting.length > 0;
    return (_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-center gap-2 flex-wrap", children: [toolbarRender?.(table), (isFiltered || isSorted) && (_jsxs(Button, { variant: "ghost", onClick: () => {
                            table.resetColumnFilters();
                            table.resetSorting();
                        }, className: "h-8 px-2 lg:px-3", children: ["Reset", _jsx(Cross2Icon, { className: "ml-2 h-4 w-4" })] }))] }), _jsx("div", { className: "flex-1" }), _jsxs("div", { className: "flex items-center gap-2 flex-wrap", children: [_jsx(DataTableViewOptions, { table: table }), _jsxs(Button, { variant: "outline", size: "sm", className: "ml-auto hidden h-8 lg:flex", onClick: () => {
                            const csvConfig = mkConfig({
                                fieldSeparator: ',',
                                filename: 'data',
                                decimalSeparator: '.',
                                useKeysAsHeaders: true,
                            });
                            const renderCellValue = (cell) => {
                                const rendered = cell.renderValue();
                                if (rendered === null) {
                                    return undefined;
                                }
                                if (['string', 'number', 'boolean', 'undefined'].includes(typeof rendered)) {
                                    return rendered;
                                }
                                if (rendered instanceof Date) {
                                    return rendered.toISOString();
                                }
                                if (typeof rendered === 'object') {
                                    return JSON.stringify(rendered);
                                }
                            };
                            const rowModel = table.getCoreRowModel();
                            const rows = rowModel.rows.map(row => Object.fromEntries(row.getAllCells().map(c => [c.column.id, renderCellValue(c)]).filter(([_, v]) => v !== undefined)));
                            if (rows.length === 0) {
                                alert("No data to export");
                                return;
                            }
                            const csv = generateCsv(csvConfig)(rows);
                            download(csvConfig)(csv);
                        }, children: [_jsx(DownloadIcon, { className: "mr-2 h-4 w-4" }), "Export CSV"] })] })] }));
}
