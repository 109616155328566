"use client";
import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "../../lib/utils";
import React from "react";
import { hasClickableParent } from "@stackframe/stack-shared/dist/utils/dom";
import { getNodeText } from "@stackframe/stack-shared/dist/utils/react";
import { runAsynchronously } from "@stackframe/stack-shared/dist/utils/promises";
import { useToast } from "./use-toast";
const InlineCode = React.forwardRef((props, ref) => {
    const { toast } = useToast();
    return _jsx("code", { ref: ref, ...props, className: cn("bg-zinc-200 text-zinc-700 dark:bg-zinc-800 dark:text-zinc-300 rounded-sm px-1 cursor-pointer", props.className), onClick: (e) => {
            props.onClick?.(e);
            if (!hasClickableParent(e.currentTarget)) {
                e.stopPropagation();
                e.preventDefault();
                runAsynchronously(async () => {
                    try {
                        await navigator.clipboard.writeText(getNodeText(props.children));
                        toast({ description: 'Copied to clipboard!', variant: 'success' });
                    }
                    catch (e) {
                        toast({ description: 'Failed to copy to clipboard', variant: 'destructive' });
                    }
                });
            }
        } });
});
InlineCode.displayName = "Code";
export { InlineCode };
