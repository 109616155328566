import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { cva } from "class-variance-authority";
import { cn } from "../../lib/utils";
const badgeVariants = cva("inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2", {
    variants: {
        variant: {
            default: "border-transparent bg-primary text-primary-foreground shadow",
            secondary: "border-transparent bg-secondary text-secondary-foreground",
            destructive: "border-transparent bg-destructive text-destructive-foreground shadow",
            outline: "text-foreground",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
const Badge = React.forwardRef(({ className, variant, ...props }, ref) => {
    return (_jsx("div", { ref: ref, className: cn(badgeVariants({ variant }), className), ...props }));
});
Badge.displayName = "Badge";
export { Badge, badgeVariants };
