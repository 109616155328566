import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { cn } from "../../lib/utils";
const Input = React.forwardRef(({ className, type, ...props }, ref) => {
    return (_jsx("input", { type: type, className: cn("flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50", className), ref: ref, ...props }));
});
Input.displayName = "Input";
export { Input };
export const DelayedInput = React.forwardRef(({ delay = 500, defaultValue, ...props }, ref) => {
    const [value, setValue] = React.useState(defaultValue ?? "");
    const timeout = React.useRef(null);
    const onChange = (e) => {
        setValue(e.target.value);
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            props.onChange?.(e);
        }, delay);
    };
    return _jsx(Input, { ref: ref, ...props, value: value, onChange: onChange });
});
DelayedInput.displayName = "DelayedInput";
